import * as THREE from "three";
import backgroundImage from "../../textures/skybox/background1.jpg";

export function backgroundSphere(scene) {
  const textureLoader = new THREE.TextureLoader();
  const backgroundTexture = textureLoader.load(backgroundImage);
  const backgroundGeometry = new THREE.SphereGeometry(500, 32, 32);
  backgroundGeometry.scale(-1, 1, 1); // Invert the geometry to render the inside
  const backgroundMaterial = new THREE.MeshBasicMaterial({
    map: backgroundTexture,
  });
  const backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);
  backgroundMesh.scale.set(1.2, 0.7, 1); // Scale the mesh to make it oval (elongated along the X-axis)
  scene.add(backgroundMesh);
  return backgroundMesh;
}
