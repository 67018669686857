export const handleIslandClick = (islandName, setActiveIsland, setIsFullPage) => {
  setActiveIsland(islandName);
  setIsFullPage(false); // Resetting to ensure non-full screen bubble is shown first
};

export const toggleFullPage = (setIsFullPage) => {
  setIsFullPage((prevIsFullPage) => {
    const newState = !prevIsFullPage;
    return newState;
  });
};

export const closeBubble = (setActiveIsland, setIsFullPage) => {
  setActiveIsland(null);
  setIsFullPage(false); // Reset here as well to ensure correct flow
};
