import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

/*
islandInfo.js ✔
backgroundSphere.js ✔
lighting.js ✔
islandUtils.js ✔
eventListeners.js ✔
cameraUtils.js ✔
bubbleUtils.js ✔
renderIslandBubble.js
renderIslandContent.js ✔
renderFullIslandContent.js 
*/

import { islandInfo } from "../data/islandInfo";
import { backgroundSphere } from "./setup/backgroundSphere";
import { addLighting } from "./setup/lighting";
import { loadIsland } from "../utils/islandUtils";
import { eventListeners } from "./handlers/eventListeners";
import { checkCameraCollision } from "../utils/cameraUtils";
// import { renderIslandContent } from "./bubbleContent/IslandContent";
import { useAtom } from "jotai";
import { activeIslandAtom, fullPageAtom } from "./state";

import IslandContent from "./bubbleContent/IslandContent";

import "../islandBubble.css";

function ThreeCanvas() {
  const mountRef = useRef(null);

  const [activeIsland, setActiveIsland] = useAtom(activeIslandAtom);
  const [isFullPage, setIsFullPage] = useAtom(fullPageAtom);

  useEffect(() => {
    const defaultCameraPosition = new THREE.Vector3(0, 15, 30);
    const defaultCameraTarget = new THREE.Vector3(0, 0, 0);

    const currentMount = mountRef.current;
    // Disable scrolling by adding overflow hidden to the body
    document.body.style.overflow = "hidden";

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.copy(defaultCameraPosition);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.target.copy(defaultCameraTarget);
    controls.enableZoom = true;
    controls.enablePan = false;

    // Convert degrees to radians for the azimuth angle limits
    const maxRotationAngleRadians = THREE.MathUtils.degToRad(30);

    // Set azimuth angle limits to restrict horizontal rotation
    controls.maxAzimuthAngle = maxRotationAngleRadians; // 30 degrees to the right
    controls.minAzimuthAngle = -maxRotationAngleRadians; // 30 degrees to the left

    controls.maxPolarAngle = THREE.MathUtils.degToRad(90);
    controls.minPolarAngle = THREE.MathUtils.degToRad(35);

    // Save references for use in the onMouseClick function
    currentMount.userData = { camera, controls, scene };

    // --------------
    // Sphere for the background
    const backgroundMesh = backgroundSphere(scene);
    // --------------

    // --------------
    // Lighting
    addLighting(scene);
    // --------------

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    // Load islands and create hitboxes

    // --------------
    islandInfo.forEach((info) => {
      loadIsland(scene, info.model, info.name, info.position);
    });
    // --------------

    // --------------
    eventListeners(
      scene,
      renderer,
      mouse,
      raycaster,
      camera,
      setActiveIsland,
      setIsFullPage,
      controls,
      maxRotationAngleRadians,
      defaultCameraPosition,
      defaultCameraTarget
    );
    // --------------

    function animate() {
      requestAnimationFrame(animate);
      checkCameraCollision(
        camera,
        controls,
        defaultCameraPosition,
        defaultCameraTarget
      );
      renderer.render(scene, camera);
      backgroundMesh.rotation.y += 0.0005; // Adjust rotation speed as needed
      controls.update();
    }

    animate();

    // Cleanup
    return () => {
      // Use the stored reference to the mount node for cleanup
      if (currentMount && renderer.domElement) {
        currentMount.removeChild(renderer.domElement);
      }
      // Re-enable scrolling when the component unmounts
      document.body.style.overflow = "auto";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleFullPage = () => {
    setIsFullPage((prevIsFullPage) => {
      const newState = !prevIsFullPage;
      return newState;
    });
  };

  const closeBubble = () => {
    setActiveIsland(null);
    setIsFullPage(false); // Reset here as well to ensure correct flow
  };
  // --------------

  // --------------
  const renderIslandBubble = () => {
    if (!activeIsland) return null;
  
    const island = islandInfo.find((info) => info.name === activeIsland);
    if (!island) return null;
  
    const bubbleStyle = isFullPage
      ? {
        position: "fixed",
        top: "7.5vh",
        left: "7.5vw",
        width: "85vw",
        height: "85vh",
        background: "white",
        padding: "20px",
        border: "1px solid black",
        boxSizing: "border-box",
        overflowY: "auto",
      }
      : {
        position: "absolute",
        top: "25%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        minHeight: "150px",
        background: "white",
        border: "1px solid black",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "default",
      };
  
    return (
      <div style={bubbleStyle} className={`island-${island.name}-content`}>
        <IslandContent islandName={island.name} />
        {!isFullPage && (
          <>
            <button
              style={{
                alignSelf: "flex-end",
                padding: "8px 16px",
                marginTop: "10px",
              }}
              onClick={toggleFullPage}
            >
              More Info
            </button>
            <button
              style={{
                alignSelf: "flex-end",
                padding: "8px 16px",
                marginTop: "10px",
              }}
              onClick={closeBubble}
            >
              Close
            </button>
          </>
        )}
        {isFullPage && (
          <>
            <button
              style={{
                alignSelf: "flex-end",
                padding: "8px 16px",
                marginTop: "10px",
              }}
              onClick={toggleFullPage}
            >
              Close Full Screen
            </button>
            <button
              style={{
                alignSelf: "flex-end",
                padding: "8px 16px",
                marginTop: "10px",
              }}
              onClick={closeBubble}
            >
              Close
            </button>
          </>
        )}
      </div>
    );
  };
  

  return (
    <div
      ref={mountRef}
      style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
    >
      {renderIslandBubble()}
    </div>
  );
}

export default ThreeCanvas;
