import aboutMeIsland from "../textures/islands/about-me-island.glb";
import cadetIsland from "../textures/islands/cadet-island.glb";
import codingIsland from "../textures/islands/coding-island.glb";
import contentIsland from "../textures/islands/content-island.glb";
import educationIsland from "../textures/islands/education-island.glb";
import electricalEngIsland from "../textures/islands/electrical-eng-island.glb";
import fitnessIsland from "../textures/islands/fitness-island.glb";
import gamingIsland from "../textures/islands/gaming-island.glb";
import workExperienceIsland from "../textures/islands/work-experience-island.glb";

import * as THREE from "three";

export const islandInfo = [
  {
    model: aboutMeIsland,
    name: "AboutMe",
    position: new THREE.Vector3(0, -6, 8),
    cameraPosition: new THREE.Vector3(0, 9, 20),
    cameraTarget: new THREE.Vector3(0, 0, 8),
  },
  {
    model: cadetIsland,
    name: "Cadet",
    position: new THREE.Vector3(35, -15, 3),
    cameraPosition: new THREE.Vector3(35, -15 + 15, 3 + 12),
    cameraTarget: new THREE.Vector3(35, -10, 3),
  },
  {
    model: codingIsland,
    name: "Coding",
    position: new THREE.Vector3(-30, -10, 7),
    cameraPosition: new THREE.Vector3(-30, -10 + 15, 7 + 12),
    cameraTarget: new THREE.Vector3(-30, -5, 7),
  },
  {
    model: contentIsland,
    name: "Content",
    position: new THREE.Vector3(35, 18, -15),
    cameraPosition: new THREE.Vector3(35, 18 + 15, -15 + 12),
    cameraTarget: new THREE.Vector3(35, 23, -15),
  },
  {
    model: educationIsland,
    name: "Education",
    position: new THREE.Vector3(55, 0, -20),
    cameraPosition: new THREE.Vector3(55, 0 + 15, -20 + 12),
    cameraTarget: new THREE.Vector3(55, 5, -20),
  },
  {
    model: electricalEngIsland,
    name: "ElectricalEng",
    position: new THREE.Vector3(-35, 2, -10),
    cameraPosition: new THREE.Vector3(-35, 2 + 15, -10 + 12),
    cameraTarget: new THREE.Vector3(-35, 7, -10),
  },
  {
    model: fitnessIsland,
    name: "Fitness",
    position: new THREE.Vector3(-20, 18, -35),
    cameraPosition: new THREE.Vector3(-20, 18 + 15, -35 + 12),
    cameraTarget: new THREE.Vector3(-20, 23, -35),
  },
  {
    model: gamingIsland,
    name: "Gaming",
    position: new THREE.Vector3(25, 15, -45),
    cameraPosition: new THREE.Vector3(25, 15 + 15, -45 + 12),
    cameraTarget: new THREE.Vector3(25, 20, -45),
  },
  {
    model: workExperienceIsland,
    name: "WorkExperience",
    position: new THREE.Vector3(-67, 15, -35),
    cameraPosition: new THREE.Vector3(-67, 15 + 15, -35 + 12),
    cameraTarget: new THREE.Vector3(-67, 20, -35),
  },
];
