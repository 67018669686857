import * as THREE from "three";
import { gsap } from "gsap";

function resetCamera(camera, controls, defaultCameraPosition, defaultCameraTarget) {
  // Smoothly animate camera and controls target to default positions
  gsap.to(camera.position, {
    x: defaultCameraPosition.x,
    y: defaultCameraPosition.y,
    z: defaultCameraPosition.z,
    duration: 2, // Adjust duration for smoother transition
    ease: "power2.Out", // This easing creates a smooth transition effect
  });
  gsap.to(controls.target, {
    x: defaultCameraTarget.x,
    y: defaultCameraTarget.y,
    z: defaultCameraTarget.z,
    duration: 2,
    ease: "power2.out",
    onUpdate: () => controls.update(),
  });
  controls.target.set(
    defaultCameraTarget.x,
    defaultCameraTarget.y,
    defaultCameraTarget.z
  );
}

export function checkCameraCollision(camera, controls, defaultCameraPosition, defaultCameraTarget) {
  // Define the radius for your invisible boundary
  const boundaryRadius = 250;

  // Get the camera's current position in world space
  const cameraPosition = new THREE.Vector3();
  camera.getWorldPosition(cameraPosition);

  // Calculate the distance from the camera to the center of the scene
  const distanceFromCenter = cameraPosition.length();

  // Check if the camera has moved outside the boundary
  if (distanceFromCenter > boundaryRadius) {
    // Reset camera to default position and target
    resetCamera(camera, controls, defaultCameraPosition, defaultCameraTarget);
  }
}
