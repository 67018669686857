import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { gsap } from "gsap";

export function loadIsland(scene, modelPath, name, position) {
  const loader = new GLTFLoader();
  loader.load(
    modelPath,
    (gltf) => {
      const model = gltf.scene;
      model.scale.copy(new THREE.Vector3(0.4, 0.4, 0.4));
      model.position.copy(position);
      scene.add(model);

      // Generate random parameters for the animation
      const durationUp = 1.5 + Math.random() * 1.75; // Random duration between 1.5 and 3.5 seconds for moving up
      const durationDown = 1.5 + Math.random() * 1.75; // Random duration for moving down, independent of the up movement
      const amplitude = 0.5 + Math.random() * 0.75; // Move up and down between 0.5 and 1 units
      const delay = Math.random() * 2.5; // Start animation with a delay of up to 2 seconds

      // Animate the island: Move up and down smoothly with random parameters
      gsap
        .timeline({ repeat: -1, yoyo: true, delay: delay })
        .to(model.position, {
          y: `+=${amplitude}`,
          duration: durationUp,
          ease: "sine.inOut",
        })
        .to(model.position, {
          y: `-=${amplitude}`,
          duration: durationDown,
          ease: "sine.inOut",
        });

      // Create a hitbox for the island
      const hitboxGeometry = new THREE.BoxGeometry(10, 10, 10); // Adjust size as needed
      const hitboxMaterial = new THREE.MeshBasicMaterial({
        visible: false,
      });
      const hitbox = new THREE.Mesh(hitboxGeometry, hitboxMaterial);
      hitbox.position.copy(position);
      hitbox.name = name;
      scene.add(hitbox);
    },
    undefined,
    (error) => {
      console.error(error);
    }
  );
}
