import { useAtom } from "jotai"

import { fullPageAtom } from "../state"
import { lazy } from "react";

const content = {
  "AboutMe": lazy(() => import('./content/AboutMe')),
  "Cadet": lazy(() => import('./content/Cadet')),
  "Coding": lazy(() => import('./content/Coding')),
  "Content": lazy(() => import('./content/Content')),
  "Education": lazy(() => import('./content/Education')),
  "ElectricalEng": lazy(() => import('./content/ElectricalEng')),
  "Fitness": lazy(() => import('./content/Fitness')),
  "Gaming": lazy(() => import('./content/Gaming')),
  "WorkExperience": lazy(() => import('./content/WorkExperience')),
}

const IslandContent = ({islandName}) => {
  const [isFullPage, setFullPage] = useAtom(fullPageAtom)

  const Component = content[islandName]

  return <Component isFullPage={isFullPage} setFullPage={setFullPage}/>
}

export default IslandContent