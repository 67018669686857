import { gsap } from "gsap";
import { islandInfo } from "../../data/islandInfo";
import { handleIslandClick, closeBubble } from "../../utils/bubbleUtils"

export const eventListeners = (
  scene,
  renderer,
  mouse,
  raycaster,
  camera,
  setActiveIsland,
  setIsFullPage,
  controls,
  maxRotationAngleRadians,
  defaultCameraPosition,
  defaultCameraTarget
) => {
  const onMouseClick = (event) => {
    event.preventDefault();
    const rect = renderer.domElement.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    mouse.x = x;
    mouse.y = y;
    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects(scene.children, true);
    if (intersects.length > 0) {
      const intersectedObject = intersects.find((intersect) =>
        islandInfo.some((island) => intersect.object.name === island.name)
      );
      if (intersectedObject) {
        const island = islandInfo.find(
          (info) => info.name === intersectedObject.object.name
        );
        setActiveIsland(null);
        if (island) {
          console.log("Clicked on %s", island.name);

          // Set azimuth angle limits to restrict horizontal rotation
          controls.maxAzimuthAngle = Infinity;
          controls.minAzimuthAngle = -Infinity;

          // Animate camera position
          gsap.to(camera.position, {
            x: island.cameraPosition.x,
            y: island.cameraPosition.y,
            z: island.cameraPosition.z,
            duration: 2, // Adjust duration for smoother transition
            ease: "power2.inOut", // This easing creates a smooth transition effect
            onUpdate: () => camera.updateProjectionMatrix(),
            onComplete: () => {
              setActiveIsland(island.name);
              handleIslandClick(island.name, setActiveIsland, setIsFullPage);
            },
          });

          // Animate controls target
          gsap.to(controls.target, {
            x: island.cameraTarget.x,
            y: island.cameraTarget.y,
            z: island.cameraTarget.z,
            duration: 2, // Ensure this matches the duration of the camera animation for sync
            ease: "power2.inOut",
            onUpdate: () => {
              controls.update();
            },
          });
        }
      }
    } else {
      // Set azimuth angle limits to restrict horizontal rotation
      controls.maxAzimuthAngle = maxRotationAngleRadians; // 30 degrees to the right
      controls.minAzimuthAngle = -maxRotationAngleRadians; // 30 degrees to the left
      setActiveIsland(null);
      closeBubble(setActiveIsland, setIsFullPage); // Use closeBubble to reset the states
      // If clicked outside any object, smoothly return to default view
      gsap.to(camera.position, {
        x: defaultCameraPosition.x,
        y: defaultCameraPosition.y,
        z: defaultCameraPosition.z,
        duration: 2,
        ease: "power2.inOut",
        onUpdate: () => camera.updateProjectionMatrix(),
      });
      gsap.to(controls.target, {
        x: defaultCameraTarget.x,
        y: defaultCameraTarget.y,
        z: defaultCameraTarget.z,
        duration: 2,
        ease: "power2.inOut",
        onUpdate: () => controls.update(),
      });
    }
  };

  const onWheel = (event) => {
    event.preventDefault();

    // Adjust mouse position based on the event
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    // Determine zoom direction: positive deltaY implies zooming out, negative implies zooming in.
    if (event.deltaY < 0) {
      // Zooming in
      const intersects = raycaster.intersectObjects(scene.children);
      if (intersects.length > 0) {
        const pointOfInterest = intersects[0].point;

        // Calculate a new position closer to the point of interest along the line from camera through the intersection point
        const direction = pointOfInterest
          .clone()
          .sub(camera.position)
          .normalize();
        const distance = camera.position.distanceTo(pointOfInterest) * 0.5; // Adjust this factor to control zoom amount
        const newPosition = camera.position
          .clone()
          .add(direction.multiplyScalar(distance));

        // Smoothly animate camera and controls target to this new position and point of interest
        gsap.to(camera.position, {
          x: newPosition.x,
          y: newPosition.y,
          z: newPosition.z,
          duration: 1,
        });
        gsap.to(controls.target, {
          x: pointOfInterest.x,
          y: pointOfInterest.y,
          z: pointOfInterest.z,
          duration: 1,
          onUpdate: () => controls.update(),
        });
      }
    } else {
      // Zooming out: Move back to the default position and target
      gsap.to(camera.position, {
        x: defaultCameraPosition.x,
        y: defaultCameraPosition.y,
        z: defaultCameraPosition.z,
        duration: 1,
      });
      gsap.to(controls.target, {
        x: defaultCameraTarget.x,
        y: defaultCameraTarget.y,
        z: defaultCameraTarget.z,
        duration: 1,
        onUpdate: () => controls.update(),
      });
      setActiveIsland(null);
    }
  };

  // Function to update the renderer size and camera aspect ratio on window resize
  const onWindowResize = () => {
    // Update camera aspect ratio and renderer size
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  window.addEventListener("resize", onWindowResize, false);
  renderer.domElement.addEventListener("click", onMouseClick);
  renderer.domElement.addEventListener("wheel", onWheel);
};
